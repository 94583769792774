<template>
  <div class="w-full flex flex-col gap-8 ml-3">
    <Alert
      message="Congratulations! You have finished going through the confirmation checklist. Click on confirm to finish. This action will take effect as soon as it is approved."
      variant="primary"
      class="alert-style"
    />

    <div class="flex gap-3">
      <Button
        class="font-semibold text-white"
        :background="appearance.buttonColor || defaultBTNColor"
        @click="confirmSubmit()"
      >
        Confirm
      </Button>
      <Button
        class="font-semibold text-dynamicBackBtn border border-solid border-dynamicBackBtn"
      >
        Cancel
      </Button>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import Alert from "@scelloo/cloudenly-ui/src/components/alert";
import Button from "@/components/Button";

export default {
  name: "ConfirmAssessment",
  components: { Alert, Button },
  props: {
    confirmPayload: {
      type: Object,
      default: () => {},
    },
    slug: {
      type: String,
      default: "",
    },
  },

  computed: {
    ...mapState({
      defaultBTNColor: (state) => state.orgAppearance.defaultBtnColor,
      appearance: (state) => state.orgAppearance.orgAppearanceData,
    }),
  },

  methods: {
    confirmSubmit() {
      if (this.slug === "confirm") {
        this.$_confirmEmployee(this.confirmPayload)
          .then((response) => {
            this.$toasted.success(response.data.message, { duration: 4000 });
            this.$router.back();
          })
          .catch((err) => {
            this.$toasted.error(err.response.data.message, { duration: 4000 });
          });
      } else {
        this.$_extendProbation(this.confirmPayload)
          .then(() => {
            this.$toasted.success(
              "Employee probation extention sent for approval",
              {
                duration: 5000,
              }
            );
            this.$router.back();
          })
          .catch(() => {
            this.$toasted.error(
              "Employee's Probation could not be extended please contact your admin",
              { duration: 5000 }
            );
          });
      }
    },
  },
};
</script>

<style scoped>
.alert-style {
  position: relative;
  right: 0;
  top: -5px;
  box-shadow: none;
  background: rgba(19, 181, 106, 0.06);
  color: rgba(19, 181, 106, 1);
  font-size: 16px;
  font-weight: bold;
  border: 1px solid rgba(19, 181, 106, 1);
}
</style>
