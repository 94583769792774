<template>
  <div class="w-full flex flex-col gap-3 ml-3">
    <div class="flex w-full gap-2">
      <card class="p-3 w-full">
        <div class="flex mb-1">
          <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
          <h6 class="text-blueCrayola text-xs ml-2 mt-1">Employment Date</h6>
        </div>
        <p class="ml-7 font-black text-xs">
          {{
            `${
              employmentDetails.resumptionDate
                ? $DATEFORMAT(
                    new Date(employmentDetails.resumptionDate),
                    "MMMM dd, yyyy"
                  )
                : "-"
            }`
          }}
        </p>
      </card>
      <card class="p-3 w-full">
        <div class="flex mb-1">
          <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
          <h6 class="text-carrotOrange text-xs ml-2 mt-1">Probation Period</h6>
        </div>
        <p class="ml-7 font-black text-xs uppercase">
          {{ `${settings?.probationPeriod} months` }}
        </p>
      </card>
      <card class="p-3 w-full">
        <div class="flex mb-1">
          <icon icon-name="calendar" class="text-romanSilver" size="xsm" />
          <h6 class="text-desire text-xs ml-2 mt-1">Confirmation Due Date</h6>
        </div>
        <p v-if="!employmentDetails.confirmationDueDate">-</p>
        <p v-else class="ml-7 font-black text-xs uppercase">
          {{
            $DATEFORMAT(
              new Date(employmentDetails.confirmationDueDate),
              "MMMM dd, yyyy"
            )
          }}
        </p>
      </card>
      <card class="p-3 w-full">
        <div class="flex mb-1">
          <icon icon-name="icon-grid" class="text-romanSilver" size="xsm" />
          <h6 class="text-mediumSeaGreen capitalize text-xs ml-2 mt-1">
            last performance score
          </h6>
        </div>
        <p class="ml-7 font-black text-xs">
          {{
            Math.round(employmentDetails.employeePerformanceRatingScore) ||
            "Appraisal data Unavailable"
          }}
        </p>
      </card>
    </div>

    <div class="mt-2">
      <div class="flex">
        <div class="flex flex-grow">
          <p class="mt-3 text-sm">Performance Ratings</p>
        </div>
        <div class="flex justify-end mt-2 text-flame font-semibold">
          <p class="text-base">
            Overall Score:
            {{
              Math.round(employmentDetails.employeePerformanceRatingScore) || 0
            }}
          </p>
        </div>
      </div>

      <div class="flex w-full flex-wrap mt-5 p-4 gap-y-8 bg-guyabano">
        <div
          class="w-1/3 flex p-1 flex-col perf gap-2"
          v-for="(perf, index) in performance()"
          :key="index"
        >
          <p class="text-sm font-semibold">{{ perf.criteria }}</p>
          <p class="text-sm font-semibold">{{ perf.weight }}</p>
        </div>
      </div>
    </div>

    <div class="mt-1">
      <div class="flex justify-between">
        <h5 class="mt-3 text-sm">Assessment Recommendation</h5>
        <span class="flex">
          <h5 class="mt-3 text-sm text-mediumSeaGreen">Edit</h5>
        </span>
      </div>
      <c-text
        placeholder="--Recommendation--"
        variant="w-full h-10"
        v-model="recommendation"
        :disabled="true"
      />
    </div>

    <div class="w-full mt-5 mb-2 border" />

    <div class="w-full flex flex-col gap-3">
      <h5 class="mt-3 text-sm">Additional Comments</h5>
      <span>
        <CTextarea
          placeholder="--Enter Here--"
          class="mt-2"
          value=""
          :row="20"
          :height="100"
          :col="30"
          :input="null"
          disabled
          v-model="assesementSummary"
        />
      </span>
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import CTextarea from "@scelloo/cloudenly-ui/src/components/text-area";
import Card from "@/components/Card";

export default {
  name: "ViewConfirmation",
  components: {
    Card,
    CText,
    CTextarea,
  },
  data() {
    return {};
  },
  props: {
    employmentDetails: {
      type: Object,
      default: () => {},
    },
    settings: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    recommendation() {
      return this.employmentDetails.employeeConfirmation?.recommendation;
    },
    assesementSummary() {
      return this.employmentDetails.employeeConfirmation?.assesementSummary;
    },
  },
  methods: {
    performance() {
      if (this.employmentDetails.employeeConfirmation) {
        return this.employmentDetails.employeeConfirmation.performanceRating;
      }
      return this.settings.criteria.map((element) => ({
        criteria: element.criteria,
        weight: "",
      }));
    },
  },
  mounted() {},
};
</script>
