<template>
  <div class="w-full flex flex-col gap-5 ml-3">
    <div class="flex w-full flex-col gap-3">
      <p class="text-sm">Is this confirmation subject to salary review?</p>
      <radio-button
        :options="reviewBox"
        col-span="col-span-1"
        class="mt-4 text-darkPurple"
        row-gap="gap-x-1"
        space-between="mr-2"
        v-model="reviewResponse"
      />
    </div>
    <div
      class="w-full flex flex-col gap-6 my-2"
      v-if="reviewResponse === 'true'"
    >
      <div class="w-full flex gap-3">
        <div class="w-3/5 flex flex-col gap-2">
          <p class="text-sm">Current Pay Band</p>
          <div class="w-full gap-2 flex">
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label=""
              :disabled="true"
              v-model="rangeMinimum"
            />
            <span class="flex flex-col justify-center text-sm py-2">to</span>
            <c-text
              placeholder="--Enter--"
              variant="w-full"
              label=""
              :disabled="true"
              v-model="rangeMaximum"
            />
          </div>
        </div>
        <div class="w-2/5 flex flex-col gap-2">
          <p class="text-sm">Current Annual Gross Salary</p>
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label=""
            :disabled="true"
            v-model="grossPay"
          />
        </div>
      </div>

      <div class="w-full flex gap-3">
        <div class="w-3/5 flex flex-col gap-2">
          <radio-button
            :options="reviseType"
            col-span="col-span-6"
            class="mt-1 text-darkPurple"
            row-gap="gap-x-8"
            space-between="mr-2"
            v-model="reviseTypeResponse"
          />

          <div class="w-full flex">
            <div
              class="flex w-10 h-10 border-t border-l border-b rounded-t-md rounded-b-md py-2 px-3"
              style="border-color: #878e99; margin-top: 0.45rem"
            >
              {{ reviseTypeResponse === "percentage_increase" ? "%" : "₦" }}
            </div>
            <c-text
              placeholder="--Enter--"
              variant="w-full input-rounded"
              label=""
              class="w-3/4 -mx-1"
              v-model="reviseValue"
            />
          </div>
        </div>
        <div class="w-2/5 flex flex-col gap-2 my-2">
          <p class="text-sm">New Annual Gross Salary</p>
          <c-text
            placeholder="--Enter--"
            variant="w-full"
            label=""
            :disabled="true"
            v-model="newSalary"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CText from "@scelloo/cloudenly-ui/src/components/text";
import RadioButton from "@/components/RadioButton";

export default {
  name: "Payroll",
  components: { RadioButton, CText },
  data() {
    return {
      reviewBox: [
        { name: "Yes", radioName: "review", value: true },
        { name: "No", radioName: "review", value: false },
      ],
      newAnnualSalary: this.employmentDetails.grossPay,
      reviewResponse: null,
      payrollReview: null,
      reviseTypeResponse: "percentage_increase",
      reviseType: [
        {
          name: "Increase by %",
          radioName: "revise",
          value: "percentage_increase",
        },
        {
          name: "Increase by Amount",
          radioName: "revise",
          value: "amount",
        },
      ],
      reviseValue: 0,
    };
  },
  props: {
    employmentDetails: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    /* eslint-disable vue/no-side-effects-in-computed-properties */
    newSalary() {
      let result = this.employmentDetails.grossPay;
      const reviseBy = this.reviseTypeResponse;
      if (
        reviseBy === "percentage_increase" ||
        reviseBy === "percentage_decrease"
      ) {
        const per = Number(this.reviseValue);
        const percent = per / 100;
        const percentageSolved = percent * this.employmentDetails.grossPay;
        result =
          reviseBy === "percentage_increase"
            ? percentageSolved + this.employmentDetails.grossPay
            : this.employmentDetails.grossPay - percentageSolved;
      }
      if (reviseBy === "amount") {
        const amt = Number(this.reviseValue);
        result = amt + this.employmentDetails.grossPay;
      }
      this.newAnnualSalary = result;
      this.setEmit();
      return result.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    rangeMinimum() {
      return this.convertToCurrency(
        this.employmentDetails.compensation.rangeMinimum
      );
    },

    rangeMaximum() {
      return this.convertToCurrency(
        this.employmentDetails.compensation.rangeMaximum
      );
    },

    grossPay() {
      return this.convertToCurrency(this.employmentDetails.grossPay);
    },
  },

  watch: {
    reviewResponse(data) {
      this.payrollReview = data
      this.setEmit();
    }
  },

  methods: {
    setEmit() {
      const payrollPayload = {
        payrollReview: this.payrollReview === "true",
        newGrossAnnualSalary: this.newAnnualSalary,
      };
      this.$emit("payload", payrollPayload);
    }
  },
  mounted() {},
};
</script>

<style scoped>
.input-rounded {
  border-top-left-radius: 0px !important;
  border-bottom-left-radius: 0px !important;
}
</style>
